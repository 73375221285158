import * as React from 'react';
import loadable from '@loadable/component';

import { COLORS } from '../utils/theme';
import SEO from '../components/SEO';
import Background from '../components/Background';
import HeaderTitle from '../components/HeaderTitle';
import FeaturedTools from '../components/Tools/FeaturedTools';
import Library from '../components/Tools/Library';
import AllTools from '../components/Tools/AllTools';
import { contentFetch } from '../lib/content-fetcher';
import qs from 'qs';

import { SELECTION_MODE } from '../components/Filters/constants';
import { PAGES_TYPES } from '../utils/constants';

const Header = loadable(() => import('../components/Header'));
const Footer = loadable(() => import('../components/Footer'));

const Tools = () => {
  const [menuIsShown, showMenu] = React.useState(false);
  const [featuredTools, setFeaturedTools] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [profiles, setProfiles] = React.useState([]);
  const [resourceTypes, setResourceTypes] = React.useState([]);

  const getTools = (filters = {}) => {
    const toolsQuery = qs.stringify(
      {
        filters: filters,
        populate: ['tool_tags'],
        fields: ['slug', 'title', 'short_description'],
        sort: ['createdAt:desc'],
      },
      {
        encodeValuesOnly: true,
      },
    );

    contentFetch({
      pathname: `/tools?${toolsQuery}`,
      setState: setTools,
      transform: (d) => d?.data,
    });
  };

  React.useEffect(() => {
    contentFetch({
      pathname: '/profiles',
      setState: setProfiles,
      transform: (d) =>
        d?.data.map((item) => ({
          ...item.attributes,
          id: item.id,
        })),
    });
    contentFetch({
      pathname: '/resource-types',
      setState: setResourceTypes,
      transform: (d) =>
        d?.data.map((item) => ({
          ...item.attributes,
          id: item.id,
        })),
    });
    const featuredToolsQuery = qs.stringify(
      {
        filters: {
          Featured: true,
        },
        populate: ['tool_tags'],
        fields: ['slug', 'title', 'short_description'],
        sort: ['createdAt:desc'],
      },
      {
        encodeValuesOnly: true,
      },
    );
    contentFetch({
      pathname: `/tools?${featuredToolsQuery}`,
      setState: setFeaturedTools,
      transform: (d) => d?.data,
    });

    getTools();
  }, []);

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Tools"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />
      <HeaderTitle menuIsShown={menuIsShown} type={PAGES_TYPES.TOOLS} />
      <FeaturedTools menuIsShown={menuIsShown} tools={featuredTools} />
      <Library
        menuIsShown={menuIsShown}
        filters={[
          {
            name: `I'm`,
            id: 'profile_types',
            values: profiles,
            selection: SELECTION_MODE.MULTIPLE_CHOICE,
          },
          {
            name: `Tools type`,
            id: 'resource_types',
            values: resourceTypes,
            selection: SELECTION_MODE.SINGLE_CHOICE,
          },
        ]}
        applyFilters={getTools}
      />
      <AllTools menuIsShown={menuIsShown} tools={tools} />
      <Footer />
    </Background>
  );
};

export default Tools;
