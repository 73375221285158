import * as React from 'react';

import { Container, Title, TagContainer, Tag, Description, LinkToDetail } from './styles';

import Markdown from '../../Markdown';

const FeaturedTool = ({ tool, negative, small }) => {
  return (
    <LinkToDetail to={`/tools/${tool?.attributes?.slug}`}>
      <Container negative={negative} small={small}>
        <Title negative={negative} small={small}>
          {tool?.attributes?.title}
        </Title>
        <TagContainer>
          {tool?.attributes?.tool_tags?.data?.length
            ? tool?.attributes?.tool_tags?.data?.map((tag) => <Tag>{tag?.attributes?.name}</Tag>)
            : null}
        </TagContainer>
        <Description negative={negative} small={small}>
          <Markdown>{tool?.attributes?.short_description}</Markdown>
        </Description>
      </Container>
    </LinkToDetail>
  );
};

export default FeaturedTool;
