import * as React from 'react';

import MiniSectionTitle from '../../MiniSectionTitle';
import Row from '../../Row';
import FeaturedTool from '../FeaturedTool';
import { Logo, MenuButton } from '../../HomeHeader/styles';
import textureImgOrange2 from '../../../images/textures/orange-2.png';
import textureImgViolet2 from '../../../images/textures/violet-2.png';

import { Container, TextureImg } from './styles';

const FeaturedTools = ({ tools, menuIsShown }) => {
  return (
    <Container>
      <Logo visible />
      <MenuButton visible menuIsShown={menuIsShown} />
      <MiniSectionTitle white>Featured Tools</MiniSectionTitle>
      <Row flexDirectionMobile="column" noSpace>
        {tools?.length
          ? tools.map((tool, index) => <FeaturedTool key={index} tool={tool} />)
          : null}
      </Row>
      <TextureImg src={textureImgViolet2} top="-8%" left="60%" />
      <TextureImg src={textureImgOrange2} top="60%" left="10%" />
    </Container>
  );
};

export default FeaturedTools;
